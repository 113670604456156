import React from 'react';
import '../Buttons/Buttons';
import './Home.css';
import videoBg from '../../video/Waves.mp4';
import Waves from '../../images/Waves.png';
import { ReactComponent as SLogo } from '../../images/SLogoTesti.svg';


function Home() {
  return (
    <div>
      <video src={videoBg} poster={Waves} autoPlay loop muted />
        <div id="etusivu" className='hero-container'>
          <div className='hero-container2'>
          <SLogo className='logo' />
          {/*<h1><span className="white1">SWAHN</span> <span className='blue'>WASA</span></h1>*/}
            <p>Turvalliset venekuljetukset ja ammattitaitoinen kunnossapito Vaasassa</p>
            <div className='hero-btns'>
              <a className='btn' href="/#palvelut">Palvelut</a>
              <a className='btn' href="/#yhteystiedot">Yhteystiedot</a>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Home
