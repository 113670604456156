import Kunnossapito from '../kunnossaimages/Kunnossapito.JPG';
import Kunnossapito2 from '../kunnossaimages/Kunnossapito2.JPG';
import Kunnossapito3 from '../kunnossaimages/Kunnossapito3.JPG';
import Kunnossapito4 from '../kunnossaimages/Kunnossapito4.JPG';
import Kunnossapito5 from '../kunnossaimages/Kunnossapito5.JPG';



export const SliderKunnossaData = [
    {
        Image: Kunnossapito
    },
    {
        Image: Kunnossapito2
    },
    {
        Image: Kunnossapito3
    },
    {
        Image: Kunnossapito4
    },
    {
        Image: Kunnossapito5
    },
]