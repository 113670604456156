import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import './Contact.css';


function Contact() {
    return (
        
      <div id="yhteystiedot" className='contact-container'>
        <div className='contact-container-contents'>
          <h1 className='tiedot'>Yhteystiedot</h1>

              <div className='molemmat'>
                <div>
                <div className='subheads1'>
                    <p className='name'>Juuso Swahn</p>
                    <hr></hr>
                    <p className='phone'>045 806 4566</p>
                    
                </div>
                  <div className='subheads3'>
                    <p className='name'>Jörgen Swahn</p>
                    <hr></hr>
                    <p className='phone'>040 750 4292</p>
                </div>
                </div>
                <div className='subheads2'>
                    <p className='address'>Rinnakkaistie 17</p>
                    <p className='address'>65350 Vaasa</p>
                    <hr></hr>
                    <p className='address'>Swahn Wasa Oy</p>
              <p className='email'>swahnwasa@gmail.com</p>
              <hr></hr>
                  <div className='link1'>
                    <a className='facebook' href="https://www.facebook.com/swahnwasaoy/">
                    <FaFacebook/>
                    </a>
                    <a className='instagram' href="https://www.instagram.com/swahnwasaoy/">
                    <FaInstagram/>
                    </a>
                  </div>
                </div>
              </div>
        </div>
      </div>
      
  )
}

export default Contact

            