import React from 'react';
import './Venekuljetukset1.css';
import ImageSlider from '../VeneSlider/ImageSlider';
import { SliderData } from '../VeneSlider/SliderData';


const Venekuljetukset1 = () => {

    return (
        <>
            <ImageSlider slides={SliderData} />
        </>
    );
};

export default Venekuljetukset1