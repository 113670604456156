import React from 'react';

function NoPage() {
  
  
  return (
    
      <div className='NoPage' style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: '50px',

      }}>
          <h1 style={{margin: '10px'}}>404</h1>
          <h2>Not Found</h2>
      </div>
      
  )
}

export default NoPage
