import React from 'react';


function Kunnossapito2() {
    return (
        
      <div id="vene" className='venekuljetukset2'>
        <div className='vene-container-contents'>
          <h1 className='vene-tiedot'>Kunnossapito</h1>

                <div className='vene-molemmat'>
                  <div className='vene-subheads1'>
                    <p className='vene-paragraph'>Pidämme kiinteistösi ulkoalueet huollettuna ympäri vuoden.</p>
                  </div>

                  <div className='vene-subheads2'>
                    <p className='vene-paragraph'>Lumitöiden oikea ajoitus sekä liukkauden torjunta takaavat turvallisen ja esteettömän liikkumisen.</p>
                  </div>
                </div>
          </div>
        </div>
      
      
  )
}

export default Kunnossapito2