import React, {useState} from "react";
import { SliderData } from "./SliderData";
import { FaArrowAltCircleRight, FaArrowAltCircleLeft } from 'react-icons/fa';


const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1)
    }

    const goToSlide = index => {
        setCurrent(index)
    }
    
    if (!Array.isArray(slides) || slides.length <= 0) {
        return null
    }
    
    return (
        <div className="background">
            <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
            <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
             {SliderData.map((slide, index) => {
                return (
                    <div>
                        <div className={index === current ? 'slideback active' : 'backslide'} key={index}>
                            {index === current && (<img src={slide.Image} alt="vene kuva" className="veneback" />)}
                        </div>
                    </div>
                )
            })}
        <section className="slider">
            {SliderData.map((slide, index) => {
                return (
                    <div>
                        <div className={index === current ? 'slide active' : 'slide'} key={index}>
                            {index === current && (<img src={slide.Image} alt="vene kuva" className="veneimage" />)}
                        </div>
                    </div>
                )
            })}
        </section>
            <div className="dotstyles">
                {SliderData.map((slide, index) => (    
                        <div className={index === current ? 'slideactivedot' : 'dot'} key={index} onClick={() => goToSlide(index)} >•</div>  
                ))}
            </div>
        </div>
    )
};

export default ImageSlider