import React, { useState } from 'react';
import './Navbar.css';
import { HashLink as Link } from 'react-router-hash-link';
import { ReactComponent as SLogo } from '../../images/SLogoTesti.svg';

const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false)

  return (                     
    <div className='Navbar'>
        <Link className="nav-logo" to="/#etusivu"><SLogo /></Link>
              <div className={`nav-items ${isOpen && "open"}`}>
                  <Link onClick={() => setIsOpen(!isOpen)} to="/#etusivu">Etusivu</Link>
                  <Link onClick={() => setIsOpen(!isOpen)} to="/#palvelut">Palvelut</Link>
                  <Link onClick={() => setIsOpen(!isOpen)} to="/#yhteystiedot">Yhteystiedot</Link>
        
              </div>                     
              <div className={`nav-toggle ${isOpen && "open"}`}  onClick={() => setIsOpen(!isOpen)}>
                <div className="bar"></div>
              </div>
      </div>
  )
}

export default Navbar