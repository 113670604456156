import React from 'react';
import Home from './Home/Home'; 
import Services from './Services/Services';
import Contact from './Contact/Contact';


const Pages = () => {
  return (
    <>
      <Home />
      <Services />
      <Contact />
    </>
  );
};

export default Pages;