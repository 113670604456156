import React from 'react';
import './Venekuljetukset2.css';

function Venekuljetukset2() {
    return (
        
      <div id="vene" className='venekuljetukset2'>
        <div className='vene-container-contents'>
          <h1 className='vene-tiedot'>Venekuljetukset</h1>

                <div className='vene-molemmat'>
                  <div className='vene-subheads1'>
                    <p className='vene-paragraph'>Kuljetamme veneitä Vaasan alueella, hydraulisella trailerilla, maasturin trailerilla tai asiakkaan omalla trailerilla.
                   </p>
                  </div>

                  <div className='vene-subheads2'>
                    <p className='vene-paragraph'>Hoidamme kuljetukset niin viikolla kuin viikonloppuisin, kellonajasta riippumatta!</p>
                  </div>
                </div>
          </div>
        </div>
      
      
  )
}

export default Venekuljetukset2