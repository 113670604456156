import React from 'react';
import Kunnossapito1 from './Kunnossapito1';
import Kunnossapito2 from './Kunnossapito2';
import ScrollToTop from "../../../../ScrollToTop";

function Kunnossapito() {
    return (
      <div className='kunnossapito'>
        <ScrollToTop/>
        <Kunnossapito1 />
        <Kunnossapito2 />
        </div>
      
   
      
  )
}

export default Kunnossapito