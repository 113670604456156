import React from 'react';
import Venekuljetukset1 from './Venekuljetukset1';
import Venekuljetukset2 from './Venekuljetukset2';
import ScrollToTop from "../../../../ScrollToTop";

function Venekuljetukset() {
    return (
      <div className='venekuljetukset'>
        <ScrollToTop />
        <Venekuljetukset1 />
        <Venekuljetukset2 />
        </div>
      
   
      
  )
}

export default Venekuljetukset