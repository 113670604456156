import React from 'react';
import ImageKunnossaSlider from '../KunnossaSlider/ImageKunnossaSlider';
import { SliderKunnossaData } from '../KunnossaSlider/SliderKunnossaData';


const Kunnossapito1 = () => {

    return (
        <>
            <ImageKunnossaSlider slides={SliderKunnossaData} />
        </>
    );
};

export default Kunnossapito1