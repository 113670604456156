import Venekuljetus from '../veneimages/Venekuljetus.JPG';
import Venekuljetus2 from '../veneimages/Venekuljetus2.JPG';
import Venekuljetus3 from '../veneimages/Venekuljetus3.JPG';
import Venekuljetus4 from '../veneimages/Venekuljetus4.JPG';
import Venekuljetus5 from '../veneimages/Venekuljetus5.JPG';
import Venekuljetus6 from '../veneimages/Venekuljetus6.JPG';
import Venekuljetus7 from '../veneimages/Venekuljetus7.JPG';
import Venekuljetus8 from '../veneimages/Venekuljetus8.JPG';
import Venekuljetus9 from '../veneimages/Venekuljetus9.JPG';
import Venekuljetus10 from '../veneimages/Venekuljetus10.JPG';
import Venekuljetus11 from '../veneimages/Venekuljetus11.JPG';


export const SliderData = [
    {
        Image: Venekuljetus
    },
    {
        Image: Venekuljetus2
    },
    {
        Image: Venekuljetus3
    },
    {
        Image: Venekuljetus4
    },
    {
        Image: Venekuljetus5
    },
    {
        Image: Venekuljetus6
    },
    {
        Image: Venekuljetus7
    },
    {
        Image: Venekuljetus8
    },
    {
        Image: Venekuljetus9
    },
    {
        Image: Venekuljetus10
    },
    {
        Image: Venekuljetus11
    },
]