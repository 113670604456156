import { BrowserRouter, Routes ,Route} from 'react-router-dom';
import './App.css';
import Kunnossapito from './components/Services/Sivut/Kunnossapito/KunnossaSivut/Kunnossapito';
import Venekuljetukset from "./components/Services/Sivut/Venekuljetukset/VeneSivut/Venekuljetukset";
import Layout from './components/Layout';
import Pages from './components/Pages';
import NoPage from './components/NoPage';

export default function App() {

  return (
    
    <BrowserRouter>
      <Routes>
        
      <Route path="/" element={<Layout/>}>
          <Route index element={<Pages />} />
          <Route path="venekuljetukset" element={<Venekuljetukset />} />
          <Route path="kunnossapito" element={<Kunnossapito />} />
          <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}
