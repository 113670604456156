import React from 'react';
import './Services.css';
import TrakVene from '../../images/Vene3m.jpg';
import Kunnossapito from '../../images/hiekotusM.jpg';
import './Sivut/Venekuljetukset/VeneSivut/Venekuljetukset.js';
import './Sivut/Kunnossapito/KunnossaSivut/Kunnossapito.js';
import { Link } from "react-router-dom";


function Services() {
  
  
  return (
    
    <div className='services-container'>
        <h1  id="palvelut" className='palvelut'>Palvelut</h1>
      <div className='services-palvelut'>

        <div className='services-vene'>
          <p className='subhead'>Venekuljetus</p>
          <img className='images' src={TrakVene} alt="vene" />
          <p className='paragraph'>Venekuljetukset turvallisesti hydraulisella trailerilla</p>
          <Link to="/venekuljetukset">Lue lisää</Link>
        </div>



        <div className='services-vene'>
          <p className='subhead'>Kunnossapito</p>
          <img className='images' src={Kunnossapito} alt="kunnossapito"/>
          <p className='paragraph'>Toimivat ja nopeat kunnossapidon palvelut</p>
          <Link to="/kunnossapito">Lue lisää</Link>
        </div>
      </div>
      </div>
      
  )
}

export default Services
